import { AppStore } from './AppStore';
import { ArrowLeft } from './ArrowLeft';
import { ArrowLeft2 } from './ArrowLeft2';
import { ArrowRight } from './ArrowRight';
import { ArrowRight2 } from './ArrowRight2';
import { ArrowUp } from './ArrowUp';
import { Audiobook } from './Audiobook';
import { Audiobook2 } from './Audiobook2';
import { Book } from './Book';
import { Building } from './Building';
import { ChevronRight } from './ChevronRight';
import { Close } from './Close';
import { Close2 } from './Close2';
import { Club } from './Club';
import { Comeeks } from './Comeeks';
import { Comicbook } from './ComicBook';
import { Download } from './Download';
import { Earth } from './Earth';
import { Ebook } from './Ebook';
import { Ebook2 } from './Ebook2';
import { Exclamation } from './Exclamation';
import { Info } from './Info';
import { Instagram } from './Instagram';
import { LeerIcon } from './Leer';
import { Linkedin } from './Linkedin';
import { LogoSkeelo } from './LogoSkeelo';
import { Menu } from './Menu';
import { Menu2 } from './Menu2';
import { Minibook } from './Minibook';
import { PlayStore } from './PlayStore';
import { Plus } from './Plus';
import { PrintOut } from './PrintOut';
import { Search } from './Search';
import { Smile } from './Smile';
import { Store } from './Store';
import { TikTok } from './TikTok';
import { Twitter } from './Twitter';
import { TwitterFilled } from './TwitterFilled';
import { ZoomIn } from './ZoomIn';
import { ZoomOut } from './ZoomOut';

export const Icon = {
  Menu2,
  Close2,
  AppStore,
  ArrowLeft,
  ArrowLeft2,
  ArrowRight,
  ArrowRight2,
  ArrowUp,
  Audiobook,
  Audiobook2,
  Book,
  Close,
  Club,
  Comeeks,
  Comicbook,
  Ebook,
  Ebook2,
  Earth,
  Instagram,
  LeerIcon,
  Linkedin,
  LogoSkeelo,
  Menu,
  Minibook,
  PlayStore,
  Plus,
  Search,
  Smile,
  Store,
  TikTok,
  Twitter,
  TwitterFilled,
  Building,
  Info,
  Exclamation,
  ChevronRight,
  Download,
  PrintOut,
  ZoomIn,
  ZoomOut,
};
